import { graphql, useStaticQuery } from 'gatsby';
import 'progress-tracker/src/styles/progress-tracker.css';
import PropTypes from "prop-types";
import React, { useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import LogoImage from '../../images/logo.png';
// import 'react-image-lightbox/style.css';
import 'react-multi-carousel/lib/styles.css';

//
const getdata = graphql`{
      posts: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {ne: "Patent Review"}}}}},
        sort: {order: DESC, fields: date},
        limit: 3
      ) {
          nodes {
            id
            uri
            title
            seo {
                metaDesc
                focuskw
            }
            slug
            date
            featuredImage {
              node {
                mediaItemUrl
              }
            }
          }
        }
      }
  `
//
const Banner = (props) => {
  const initialValue = [true, false, false, false, false, false, false]
  const [visibility, setVisibility] = React.useState(false);
  const [showForm, setShowForm] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState();
  const [steps, setSteps] = useState(initialValue);
  const childRef = useRef();
  const data = useStaticQuery(getdata);
  // const common = data.wpgraphql.page.homeFields;
  const common = {
    trustedclient: [
      {
        clientLogo: 'http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture1-1.png',
        ceoText: 'Kim Boyce',
        ceoDesignation: 'CEO',
        ceoDescription: `
        Reflect Scientific (Symbol: RSCF) is a provider of diverse products and services for the biotech, pharma, and transportation industries. We have been using early trials of PowerPatent® solutions to draft and create our cases and found that we can generate initial patent drafts in about ten minutes. The voluminous first drafts are then edited and polished in a few hours. Even with lawyer review, the cost is quite low and the quality is great. Being a public company, our regulatory people are always amazed by our prolific patent count and efficiency of our IP spend. PowerPatent is our secret weapon.
        `
      },
      {
        clientLogo: 'http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture2.png',
        ceoText: 'William Moon',
        ceoDesignation: 'VP Technology & Operations',
        ceoDescription: `
        Nu-Med Plus (Symbol NUMD) commercializes a patented and patent-pending technology to safely deliver precision doses of nitric oxide. During beta testing, we save time and effort with PowerPatent to generate first draft quickly, and then we focus our energy and expertise on intellectually rigorous and valuable tasks such as writing strategic claims and improving the AI-generated application by explaining the nuances of the invention that the AI would not know about. I estimate this software has shaved weeks from the documentation of our inventions so that our outside counsel can file for us.
        `
      },
      {
        clientLogo: 'http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture3.png',
        ceoText: 'Jesse Kim',
        ceoDesignation: 'CEO',
        ceoDescription: `
        EOFlow (Symbol: 294090.KQ) is passionate about turning technology into products that people can use. Our EOPatch wearable disposable insulin pump is only the first of many products that are in our lab and on our drawing boards. We use PowerPatent® solutions to do high-quality first draft applications. We found the software easy to use. Our outside counsel loves the patent workflow, all within our IP budget. We believe that technology can improve people’s lives, and our use of PowerPatent’s AI legal-tech is an easy decision for a public company such as ours.
        `
      },
      {
        clientLogo: 'http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture4.png',
        ceoText: 'Brian Cronquist',
        ceoDesignation: 'CEVP Technology & IPO',
        ceoDescription: `
        MonolithIC 3D is an IP company that offers 1,000x Improvement in Computer Systems by Bridging the Processor-Memory Gap using 3D chip technology. We license our IP to the largest semiconductor companies in the world. We do all the preparation and prosecution of patents in-house and we rely on PowerPatent to draft and create our cases. It can quickly create reams of detailed descriptions, from which we can finalize. This effective tool increases our productivity. I credit PowerPatent with giving me more time at the end of the day to invent more.
        `
      },
      {
        clientLogo: 'http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture6.png',
        ceoText: 'Khanh Le',
        ceoDesignation: 'COO & Co-Founder',
        ceoDescription: `
        MonolithIC 3D is an IP company that offers 1,000x Improvement in Computer Systems by Bridging the Processor-Memory Gap using 3D chip technology. We license our IP to the largest semiconductor companies in the world. We do all the preparation and prosecution of patents in-house and we rely on PowerPatent to draft and create our cases. It can quickly create reams of detailed descriptions, from which we can finalize. This effective tool increases our productivity. I credit PowerPatent with giving me more time at the end of the day to invent more.
        `
      },
      {
        clientLogo: 'http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture7.png',
        ceoText: 'Ting Chang',
        ceoDesignation: 'Executive VP & Co-Founder',
        ceoDescription: `
        MicroNOC turns buildings into grid synchronized property based clean virtual power stations to reduce energy waste. We balance your rates and balance the grid. Since our mission is to save customer cost, we are always on the lookout for innovative solutions to cut our own expenses. With PowerPatent, we were able to balance our IP cost with quality and we recently received our first patent. PowerPatent solution is the way to protect your Unicorn idea.
        `
      },
      {
        clientLogo: 'http://wp.powerpatent.com/wp-content/uploads/2023/10/Picture8.png',
        ceoText: 'Gayle Shaw Jenkins',
        ceoDesignation: 'Founder',
        ceoDescription: `
        My name is Gayle Jenkins and I am a web developer, artist, and idea-maker based in Fairborn, Ohio. I enjoy the puzzle of exploring & understanding the problems and limitations that exist in systems and then the challenge of envisioning and creating solutions. Our company created our IP portfolio at insanely low cost using PowerPatent. We are no expert at patenting, and we were able to use PowerPatent® to create IP inexpensively and in a timely manner. This is a great tool for startup founders. I highly recommend PowerPatent solutions for patent creation.
        `
      },
      {
        clientLogo: 'http://wp.powerpatent.com/wp-content/uploads/2023/10/ni.png',
        ceoText: 'Linbo Yang',
        ceoDesignation: 'Principal Engineer',
        ceoDescription: `
        AIXScan creates new digital tomosynthesis (DTS) solution that eliminates overlapping anatomy or composite artifact. DTS facilitates improved lesion detection, depth localization and contrast resolution compared with regular radiography. We used PowerPatent® solutions to draft 12 high-quality utility applications. PowerPatent is easy to use and its attorney collaboration ability ensures we have white-glove service. We are very happy with PowerPatent IP solutions.
        `
      },
    ]
  }
  const posts = data.posts;
  const brands = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const trustedBy = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  //
  const clickOnStep = (index) => {
    const reset = Array(7).fill(false);
    reset[index] = true;
    setSteps(reset);
  }
  //
  // const popupCloseHandler = (e) => {
  //   setShowForm(true);
  // };

  // const openPopup = (email = '') => {
  //   if (email) {
  //     setEmail(email);
  //   }
  //   //
  //   childRef.current?.open();
  // }

  // const submit = (e) => {
  //   e.preventDefault();
  //   if (name && email) {
  //     const data = {
  //       email: email,
  //       name: name,
  //       phone: phone || '',
  //       subject: `[PowerPatent] ${email} request a Demo`,
  //       message: '',
  //       site: 'powerpatent.com'
  //     }
  //     // submit campagin
  //     submitActiveCampaignForm({ name: name, email: email, phone: phone }, false);
  //     // close popup
  //     // childRef.current?.close();
  //     setShowForm(false);
  //     setEmail('');
  //     setName('');
  //     setPhone('');
  //   } else {
  //       alert('Please fill in all fields.');
  //   }
  // }

  return (

    <>
        <main>
            <section className='py-5'>
              <div className='container'>
                <div className="py-4">
                    <div className="box-shadow anim-box bg-white">
                        <div className="row gap-md-0 gap-4 align-items-center flex-md-row-reverse flex-column">
                            <div className="col-md-6">
                                <div className="lotie-anim" id="animation8">
                                    <img src="/assets/img/animations/powerpatent-testimonial.gif" alt="Animation"
                                        className="img-fluid"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 className="anim-title pb-4">Industry Recognition and Trust</h3>
                                <p className="anim-desc">PowerPatent has earned the trust and recognition of the patent
                                    law community. Many law firms, corporations, and inventors rely on PowerPatent
                                    for their patent drafting needs, and the platform has garnered positive reviews
                                    and testimonials from satisfied users. PowerPatent's track record of delivering
                                    excellent results and its commitment to customer satisfaction solidify its
                                    position as a trusted and reputable provider of patent drafting solutions.</p>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
            </section>

            <section class="py-5">
                <div class="container">
                    <h2 class="sec-title text-center">Trusted By</h2>
                    <div class="owl-carousel" id="companies">
                    <Carousel responsive={brands} showDots={true} arrows={false} autoPlay={true}>
                        <div class="item">
                          <img src="./assets/img/brands/intra-corp-logo-1.webp"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/Cohen-logo.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/patent-pc-1.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/building-lens.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/mitre-logo-removebg-preview.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/Screenshot-2023-06-25-at-9.16-1.jpg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/image-13.jpg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/logo-2-2.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/image-14.jpg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/PLLAY-Logo-Ver2.webp"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/image-15.jpg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/image-18.jpg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/logo-ccf.svg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/footer_logo-1.png"/>
                        </div>
                        <div class="item"><img
                            src="./assets/img/brands/stack-logo-1.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/logo-did-color@2x-55x98-1.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/mirro-logo-1.webp"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/adapt-logo-1.png"/>
                        </div>
                        <div class="item"><img
                            src="./assets/img/brands/tran-logo.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/intra-corp-logo-1.webp"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/Cohen-logo.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/patent-pc-1.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/building-lens.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/mitre-logo-removebg-preview.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/Screenshot-2023-06-25-at-9.16-1.jpg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/image-13.jpg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/logo-2-2.png"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/image-14.jpg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/PLLAY-Logo-Ver2.webp"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/icon2.jpg"/>
                        </div>
                        <div class="item">
                          <img src="./assets/img/brands/icon1.jpg"/>
                        </div>
                        {/* <!-- Add more items as needed --> */}
                    </Carousel>
                    </div>
                </div>
            </section>
            
            <section class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <img src="./assets/img/side-img.jpg" alt="Side Image" class="img-fluid"/>
                        </div>
                        <div class="col-md-6 pt-md-0 pt-4">
                            <div class="pe-lg-5 me-lg-5">
                                <h2 class="s1-title pb-3 pr-0">Lifesaver For <span class="text-primary">Patent </span>
                                    Prosecutors</h2>
                                <p class="s1-subtitle">PowerPatent makes repetitive work easy so you can have a life and
                                    still concentrate on what's important: building strategic patents for clients</p>
                            </div>
                            <p class="s1-desc">Patents are complex documents. They often contain many sections and parts
                                that refer to each other. Because even minor inconsistencies can lead to costly
                                litigation, patent application must be carefully drafted and it can take significant
                                time and money for patent drafts to be drafted and reviewed prior to
                                filling.<br/><br/>Traditionally, patent professionals have to balance between cost and the
                                time they spend perfecting their patent drafts and avoid costly mistakes down the
                                line.<br/><br/>PowerPatent's Generative AI drafting tool allows patent professionals to
                                conduct thorough, efficient patent writing with cost-effective reviews of their patent
                                applications and analyze them in minutes. In contrast to other AI tools that generate
                                all text based on the claims…</p>
                            <p class="m-0">
                                <a href="#" class="fw-bold text-warning">Read More...</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-5 border-top">
                <div class="container">
                    <div class="col-lg-8 mx-auto mb-4">
                        <h2 class="sec-title text-center"> Patent Prosecution Software that streamlines each step of the
                            process </h2>
                    </div>
                    <div class="d-flex flex-wrap processes">
                        <div class={`process ${steps[0] ? 'active' : ''} text-center py-5 px-1`} onClick={() => clickOnStep(0)}>
                            <h2 class="p-title"> 1 </h2>
                            <p class="m-0 p-desc">Invention Disclosure Capture</p>
                        </div>
                        <div class={`process ${steps[1] ? 'active' : ''} text-center py-5 px-1`} onClick={() => clickOnStep(1)}>
                            <h2 class="p-title"> 2 </h2>
                            <p class="m-0 p-desc">Flowchart & Drawing Management</p>
                        </div>
                        <div class={`process ${steps[2] ? 'active' : ''} text-center py-5 px-1`} onClick={() => clickOnStep(2)}>
                            <h2 class="p-title"> 3 </h2>
                            <p class="m-0 p-desc">Graphical Claim Drafting</p>
                        </div>
                        <div class={`process ${steps[3] ? 'active' : ''} text-center py-5 px-1`} onClick={() => clickOnStep(3)}>
                            <h2 class="p-title"> 4 </h2>
                            <p class="m-0 p-desc">Computer Aided Description Drafting</p>
                        </div>
                        <div class={`process ${steps[4] ? 'active' : ''} text-center py-5 px-1`} onClick={() => clickOnStep(4)}>
                            <h2 class="p-title"> 5 </h2>
                            <p class="m-0 p-desc">Diagnostics to catch §112 and claim issue</p>
                        </div>
                        <div class={`process ${steps[5] ? 'active' : ''} text-center py-5 px-1`} onClick={() => clickOnStep(5)}>
                            <h2 class="p-title"> 6 </h2>
                            <p class="m-0 p-desc">Inventor / Client Collaboration</p>
                        </div>
                        <div class={`process ${steps[6] ? 'active' : ''} text-center py-5 px-1`} onClick={() => clickOnStep(6)}>
                            <h2 class="p-title"> 7 </h2>
                            <p class="m-0 p-desc">Private PAIR Integration</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Step 1 */}
            {
              steps[0] && <section class="py-5">
              <div class="container">
                  <div class="row">
                      <div class="col-md-6 pb-md-0 graphical">
                          <div class="position-relative pe-xl-5">
                              <img src="./assets/img/steps/step1-img0.png" alt="Image" class="img-fluid"/>
                          </div>
                      </div>
                      <div class="col-md-6 graphical-content">
                          <h2 class="sec-title mb-4 text-primary">Background And Summary<br/>Of The Invention</h2>
                          <p class="sec-bigdesc">PowerPatent can autogenerate a draft background for your review based on the title of the invention.</p>
                          <p class="sec-bigdesc">The user enters a summary of the invention highlighting the novelty and inventiveness of the invention.</p>
                          <p class="sec-bigdesc">A preliminary set of claims can be automatically drafted for user review.</p>
                          <div>
                              <button onClick={() => props.openPopup()} class="btn btn-warning btn-lg">Request Demo</button>
                          </div>
                      </div>
                  </div>
              </div>
            </section>
            }

            {/* Step 2 */}
            {
              steps[1] && <section class="py-5">
              <div class="container">
                  <div class="row">
                      <div class="col-md-6 pb-md-0 graphical">
                        <div className='row'>
                          <div className='col-md-6'>
                            <div class="pe-xl-5">
                                <img src="./assets/img/steps/step2-img1.png" alt="Image" class="rounded-circle" style={{width: '100%'}}/>
                                <p className='text-muted text-center'>Fig.1</p>
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div class="pe-xl-5" style={{marginTop: '20%'}}>
                                <img src="./assets/img/steps/step2-img2.png" alt="Image" class="rounded-circle" style={{width: '100%'}}/>
                                <p className='text-muted text-center'>Fig.2</p>
                            </div>
                          </div>
                          <div className='col-md-5'>
                            <div class="pe-xl-5" style={{marginTop: '20%'}}>
                                <img src="./assets/img/steps/step2-img3.png" alt="Image" class="rounded-circle" style={{width: '100%'}}/>
                                <p className='text-muted text-center'>Fig.3</p>
                            </div>
                          </div>
                          <div className='col-md-2'>
                            <div class="pe-xl-5" style={{marginTop: '20%'}}>
                                <img src="./assets/img/steps/step2-img4.png" alt="Image" class="rounded-circle" style={{height: '200px'}}/>
                                <p className='text-muted text-center'>Fig.4</p>
                            </div>
                          </div>
                          <div className='col-md-5'>
                            <div class="pe-xl-5" style={{marginTop: '20%'}}>
                                <img src="./assets/img/steps/step2-img5.png" alt="Image" class="rounded-circle" style={{width: '100%'}}/>
                                <p className='text-muted text-center'>Fig.5</p>
                            </div>
                          </div>
 

                        </div>


                      </div>
                      <div class="col-md-6 graphical-content">
                          <h2 class="sec-title mb-4 text-primary">Flowchart & Drawing Management</h2>
                          <p class="sec-bigdesc">Fig. 1 shows an exemplary front  view of a car with 2 wheels in  the front and one wheel in the  back.</p>
                          <p class="sec-bigdesc">Fig. 2 shows an exemplary side  view of the car of Fig. 1.</p>
                          <p class="sec-bigdesc">Fig. 3 shows an exemplary front  view of the car.</p>
                          <p class="sec-bigdesc">Fig. 4 shows an exemplary  illustration of a top view of the  car.</p>
                          <p class="sec-bigdesc">Fig. 5 shows an exemplary front  view of a car with 2 wheels in  the front and one wheel in the  back.</p>
                          <div>
                              <button onClick={() => props.openPopup()} class="btn btn-warning btn-lg">Request Demo</button>
                          </div>
                      </div>
                  </div>
              </div>
            </section>
            }

            {/* Step 3 */}
            {
              steps[2] && <section class="py-5">
              <div class="container">
                  <div class="row">
                      <div class="col-md-6 pb-md-0 graphical">
                          <div class="position-relative pe-xl-5">
                              <img src="./assets/img/steps/step3-img0.png" alt="Image" class="img-fluid"/>
                          </div>
                      </div>
                      <div class="col-md-6 graphical-content">
                          <h2 class="sec-title mb-4 text-primary">Claims</h2>
                          <p class="sec-bigdesc">The claims section of your patent application is one of the most important parts. </p>
                          <p class="sec-bigdesc">PowerPatent software helps you visually draft concise and comprehensive claims that fully protect the invention.</p>
                          <p class="sec-bigdesc">PowerPatent can even generate a first draft of the claims based on your summary of the invention.</p>
                          <div>
                              <button onClick={() => props.openPopup()} class="btn btn-warning btn-lg">Request Demo</button>
                          </div>
                      </div>
                  </div>
              </div>
            </section>
            }
            {/* Step 4 */}
            {
              steps[3] && <section class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 pb-md-0 graphical">
                            <div class="position-relative pe-xl-5">
                                <img src="./assets/img/review2-1.jpg" alt="Image" class="img-fluid"/>
                                <img src="./assets/img/Group-6.png" alt="Image Car" class="car-img"/>
                            </div>
                        </div>
                        <div class="col-md-6 graphical-content">
                            <h2 class="sec-title mb-4 text-primary"> Graphical Claim Drafting</h2>
                            <p class="sec-bigdesc mb-5">PowerPatent helps you maximise the benefits of drawings to
                                provide a clear understanding of the invention<br/><br/>You can annotate a part list for
                                each drawing, and PowerPatent can generate a detailed description for each drawing for
                                your review. </p>
                            <div>
                                <button onClick={() => props.openPopup()} class="btn btn-warning btn-lg">Request Demo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }

            {/* Step 5 */}
            {
              steps[4] && <section class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 pb-md-0 graphical">
                            <div class="position-relative pe-xl-5">
                                <img src="./assets/img/steps/step5-img0.png" alt="Image" class="img-fluid"/>
                                <img src="./assets/img/steps/step5-img1.png" alt="Image Car" class="car-img" style={{ width: '300px'}}/>
                            </div>
                        </div>
                        <div class="col-md-6 graphical-content">
                            <h2 class="sec-title mb-4 text-primary"> Diagnostics</h2>
                            <p class="sec-bigdesc">On diagnostic we have 3 sections: Part List, Noun phrases, and  Inconsistencies.</p>
                            <p class="sec-bigdesc">a. <strong>Part List</strong>: This section has a part name column and a  corresponding part number columns.</p>
                            <p  class="sec-bigdesc">b. <strong>Noun Phrases</strong>: This section shows all elements we have  detected in your case. You may want to see if you have  described concepts but not claimed. This section can help you  in identifying concepts described in your Detailed Description.</p>
                            <p  class="sec-bigdesc">c. <strong>Inconsistency</strong>: This section reports potential inconsistencies.  For example, a part number may be associated with two  conflicting names, or vice versa. The system detects such  inconsistencies for you to fix.</p>
                            <div>
                                <button onClick={() => props.openPopup()} class="btn btn-warning btn-lg">Request Demo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }

            {/* Step 6 */}
            {
              steps[5] && <section class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 pb-md-0 graphical">
                            <div class="position-relative pe-xl-5">
                                <img src="./assets/img/steps/step6-img0.png" alt="Image" class="img-fluid"/>
                            </div>
                        </div>
                        <div class="col-md-6 graphical-content">
                            <h2 class="sec-title mb-4 text-primary">Review & Collaboration</h2>
                            <p class="sec-bigdesc">You can make final edit in the review tab</p>
                            <div>
                                <button onClick={() => props.openPopup()} class="btn btn-warning btn-lg">Request Demo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
            
            {/* Step 7 */}
            {
              steps[6] && <section class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 pb-md-0 graphical">
                            <div class="position-relative pe-xl-5">
                                <img src="./assets/img/steps/step7-img0.png" alt="Image" class="img-fluid"/>
                            </div>
                        </div>
                        <div class="col-md-6 graphical-content">
                            <h2 class="sec-title mb-4 text-primary">Word Export</h2>
                            <p class="sec-bigdesc">PowerPatent supports the DOCX format patent filing through EFS-Web and Patent Center.  </p>
                            <div>
                                <button onClick={() => props.openPopup()} class="btn btn-warning btn-lg">Request Demo</button>
                            </div>
                        </div>
                    </div>
                </div>
              </section>
            }

            <section class="py-5 bg-sky">
                <div class="container">
                    <div class="col-lg-8 mx-auto mb-4 py-4">
                        <h2 class="sec-title text-center"> Trusted By</h2>
                        <h4 class="sec-subtitle text-center py-4"> You know your IP. We know first patent drafting with
                            GenAI.</h4>
                    </div>
                    <div>
                      <Carousel responsive={trustedBy} showDots={true} arrows={false} autoPlay={true}>
                      {
                        common.trustedclient.map(data => {
                          return (
                            <div class="item trusted-box">
                            <div class="d-flex gap-4 align-items-center">
                              <img src={data.clientLogo} alt="Logo" class="r-logo py-4"/>
                                <div class="">
                                    <h5 class="r-name fw-bold mb-2">{data.ceoText}</h5>
                                    <p class="r-desig fw-medium">{data.ceoDesignation}</p>
                                </div>
                            </div>
                            <p class="r-desc pt-4"> {data.ceoDescription}</p>
                        </div>
                          )
                        }
                      )}
                        {/* <!-- Add more items as needed --> */}
                      </Carousel>
                    </div>
                </div>
            </section>
            <section class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-4 px-lg-3 px-md-0">
                            <h2 class="sec-bigtitle pb-lg-3 pb-2">Our Features</h2>
                            <p class="sec-subsubtitle">One platform, everything you need to create & manage IP assets
                            </p>
                            <div class="text-center pt-5">
                                <img src="./assets/img/logos/lines.svg" alt="Lines"
                                    class="w-auto d-md-inline-block d-none"/>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 py-3">
                            <div class="box-shadow rounded-2 f-box">
                                <img src="./assets/img/icons/productivity-1.svg" alt="Icon" class="mb-3" height=""/>
                                <h3 class="f-title">Increase Billable Efficiency</h3>
                                <p class="f-desc">Work less and earn more Using Tech that increases Productivity</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 py-3">
                            <div class="box-shadow rounded-2 f-box">
                                <img src="./assets/img/icons/folder-1.svg" alt="Icon" class="mb-3" height=""/>
                                <h3 class="f-title">Build Your IP Fast</h3>
                                <p class="f-desc">First-To-File Protection in Days, Not Months better</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 py-3">
                            <div class="box-shadow rounded-2 f-box">
                                <img src="./assets/img/icons/assurance-1.svg" alt="Icon" class="mb-3" height=""/>
                                <h3 class="f-title">Check Quality with Diagnostics</h3>
                                <p class="f-desc">Create great patents and still have time with family or personal
                                    projects</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 py-3">
                            <div class="box-shadow rounded-2 f-box">
                                <img src="./assets/img/icons/image-19.svg" alt="Icon" class="mb-3" height=""/>
                                <h3 class="f-title">Rebalance Workload</h3>
                                <p class="f-desc">First-To-File Protection in Days, Not Months better</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 py-3">
                            <div class="box-shadow rounded-2 f-box">
                                <img src="./assets/img/icons/assistance-1.svg" alt="Icon" class="mb-3" height=""/>
                                <h3 class="f-title">Collaborate with Team & Lawyers</h3>
                                <p class="f-desc">Tap into your team & lawyer's wisdom to build IP</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 py-3">
                            <div class="box-shadow rounded-2 f-box">
                                <img src="./assets/img/icons/save-money-1.svg" alt="Icon" class="mb-3" height=""/>
                                <h3 class="f-title">Save Clients Money</h3>
                                <p class="f-desc">Clients win with First to File speed and Efficiency Savings from
                                    Improved Collaboration</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-5">
                <div class="container">
                    <h2 class="sec-bigtitle pb-lg-3 pb-5">Blogs</h2>
                    <div class="row">
                    {
                        posts.nodes.map(data => {
                          return (
                            <div class="col-lg-4 col-md-6 py-3 d-flex flex-column gap-3">
                              {
                                data.featuredImage?.node?.mediaItemUrl ?
                                <img src={ data.featuredImage?.node?.mediaItemUrl} alt="Blog" class="img-fluid blog-img"/>
                                :
                                <img src={LogoImage}  alt="Blog" class="img-fluid blog-img" style={{ height: 100 + '%'}}/>
                              }
                              <div class="d-flex flex-column gap-3">
                                  <h3 class="blog-title mb-0 mt-2">
                                      <a href={"/blog/" + data.slug}>{data.title}</a>
                                  </h3>
                                  <p class="blog-excerpt m-0">{ data.seo?.metaDesc || data.title } </p>
                                  <p><a href={"/blog/" + data.slug} class="text-warning fw-bold">Read More</a></p>
                              </div>
                            </div>
                          )
                        }
                      )}
                    </div>
                </div>
            </section>
        </main>
    </>
  )
}
Banner.propTypes = {
  openPopup: PropTypes.func
};
export default Banner
