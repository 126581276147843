import React, { useRef, useState } from 'react';
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"
import Form from 'react-bootstrap/Form';

import Header from '../components/Header/Header-2';
import Banner from '../components/Banner/Banner-2';
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo";
import { submitActiveCampaignForm } from '../utils/utils';
import CustomPopup from '../components/Popup/custom-popup';
import ContactPopup from '../components/Popup/contact-popup';

// import "../scss/styles.scss";
import "../styles/bootstrap.min.css";
// import "../styles/owl.carousel.min.css";
import "../styles/style.css";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const IndexPage = () => {
  const seo = {metaDesc: 'Index'}
  const childRef = useRef();
  const openPopup = () => {
    childRef.current?.open();
  }

  return (
    <Layout>
      <SEO title="Home" canonical='' seo={seo} />
      <Header home={true} menu='home'/>
      <Banner openPopup={openPopup}/>
      <ContactPopup ref={childRef}></ContactPopup>
    </Layout>
  )
};

export default IndexPage;
